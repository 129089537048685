import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import PopUp from "./elements/PopUp.vue";
import AboutUs from "./elements/AboutUs.vue";
import ThePage from "./components/ThePage.vue";
import ContactUs from "./elements/ContactUs.vue";
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import FirstBicom from "./elements/FirstBicom.vue";
import TheCounter from "./elements/TheCounter.vue";
import SecondBicom from "./elements/SecondBicom.vue";
import TheComments from "./elements/TheComments.vue";
import WelcomeComponent from "./elements/WelcomeComponent.vue";
import TheBoolean from "./elements/TheBoolean.vue";

const app = createApp(App);

app.use(store);
app.component("pop-up", PopUp);
app.component("the-page", ThePage);
app.component("about-us", AboutUs);
app.component("the-header", TheHeader);
app.component("the-footer", TheFooter);
app.component("contact-us", ContactUs);
app.component("first-bicom", FirstBicom);
app.component("the-counter", TheCounter);
app.component("second-bicom", SecondBicom);
app.component("the-comments", TheComments);
app.component("welcome-component", WelcomeComponent);
app.component("the-boolean", TheBoolean);

app.mount("#app");
