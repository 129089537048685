<template>
  <div class="header-container">
    <div class="logo-container">
      <img src="@/assets/images/workplease.png" id="colors" />
      <img src="@/assets/images/no-bg-logo.png" id="logo" />
    </div>
    <div class="links-container">
      <a href="#aboutus">O nama</a>
      <a href="#bicom1">Optima</a>
      <a href="#bicom2">Body Check</a>
      <a href="#utisci">Utisci</a>
      <a href="#kontakt">Kontakt</a>
    </div>
    <div class="burger-container">
      <input
        type="checkbox"
        id="navi-toggle"
        class="checkbox"
        ref="naviToggle"
      />
      <label for="navi-toggle" class="button">
        <span class="icon">&nbsp;</span>
      </label>
      <div class="background">&nbsp;</div>
      <nav class="nav">
        <ul class="list">
          <li class="item" v-for="link in links" :key="link.href">
            <a @click="closeMenu" class="link" :href="link.href">{{
              link.text
            }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const naviToggle = ref(null);
    const links = [
      { text: "O nama", href: "#aboutus" },
      { text: "Optima", href: "#bicom1" },
      { text: "Body Check", href: "#bicom2" },
      { text: "Utisci", href: "#utisci" },
      { text: "Kontakt", href: "#kontakt" },
    ];

    const closeMenu = () => naviToggle.value.click();
    return {
      links,
      closeMenu,
      naviToggle,
    };
  },
};
</script>

<style lang="scss" scoped src="@/assets/styles/header.scss" />
