<template>
  <section id="aboutus">
    <div class="content-container">
      <div class="first-grid-box"></div>
      <div class="text-container">
        <h3>
          Dobrodošli u Mariv<br />
          <div class="span"></div>
        </h3>
        <h1>O nama</h1>
        <p>
          Da li ste se već pitali zašto uprkos velikom blagostanju i
          neverovatnom napretku nauke sve više ljudi pati od hroničnih oboljenja
          - i to ne samo odrasli i stariji nego sve više i dece?<br />
          Iz tog razloga potražili smo metode koje bi omogućile nove postupke u
          dijagnozi i terapiji. Naš terapeutski cilj je da vam, što je god
          moguće bolje i brže, pomognemo. Želimo da vas upoznamo sa pozadinom
          hroničnih zdravstvenih smetnji i da vam u našoj ordinaciji predstavimo
          BICOM biorezonantnu metodu.<br />
          Možda ova metoda može upravo vama da pruži šansu da otkrijete pravi
          uzrok svoje bolesti i da je bez negativnih sporednih efekata lečite.
        </p>
        <button @click="isOpen = true">Prikaži više</button>
        <pop-up :open="isOpen" @close="isOpen = !isOpen">
          <p class="p">
            Danas živimo u velikom blagostanju. Našu svakodnevnicu olakšavaju
            mnogi tehnički aparati, neverovatno brza transportna i komunikaciona
            sredstva, nepregledni broj hemijskih supstanci i gotove namirnice
            svih vrsta. Iako u tome svi uživamo, ipak sve više ljudi plaća
            značajnu cenu za to.<br />
            Na ljude današnjice deluju mnoga opterećenja: hemijski dodaci našoj
            hrani i vodi za piće, zagađenje sredine, opterećenja prouzrokovana
            zračenjem, bezbrojne hemijske supstance, upotreba antibiotika u
            ishrani domaćih životinja itd.<br />
            Ova opterećenja čine da se kod mnogih pacijenata bure prelije. To se
            pokazuje u formi nejasnih osećajnih smetnji, hroničnog umora, pa sve
            do ozbiljnih telesnih smetnji. Interesantno je da se kod pacijenata
            sa istim simptomima mogu često utvrditi potpuno različiti uzroci
            bolesti. Nažalost, uobičajenim metodama skoro nije moguće otkriti
            upravo ove različite i individualne uzroke.<br />
            Važno je napomenuti da telo raspolaže sa velikim potencijalom snaga
            za samoizlečenje. Ono poseduje fenomenalni regulacioni sistem uz
            čiju pomoć stalno iznova harmonizuje i neuobičajene uticaje na
            njega.<br />
            Naravno, kada je telo u dužem vremenskom periodu izloženo isuviše
            velikom broju ili preteškim opterecenjima, ona mogu delovati na
            njega tako što sputavaju ili čak blokiraju njegove regulacione
            sposobnosti kao i samoizlečujuće snage.<br />
            U današnje vreme dobijamo slike kroz vazduh i možemo da pričamo sa
            drugima koji se nalaze na hiljade kilometara udaljenosti. Takođe, i
            u drugim područjima možemo da vidimo brz tehnički razvoj. Otkrića na
            području biofizike i kvantne mehanike otvorila su neslućene
            mogućnosti za značajan tehnički napredak. Ova otkrića su, nažalost,
            na području medicine do sada ostala zapostavljena, ali ipak čine
            osnovu biorezonantne metode.<br />
            Materija je zgusnuta energija i zrači tom energijom. Svaka
            supstanca, kao i svaka celija, svaki deo tela, ali takođe i virus,
            bakterija, polen itd., zrače energijom i time imaju jednu tačno
            određenu, tipičnu talasnu dužinu ili frekvenciju sa potpuno
            individualnom karakteristikom. Ona se obeležava i kao frekvencijska
            mustra.
          </p>
        </pop-up>
        <img
          id="mobile-image"
          src="@/assets/images/marivpictureone.jpeg"
          alt=""
        />
      </div>
      <div class="boolean-container">
        <the-boolean></the-boolean>
      </div>
      <div class="last-grid-box"></div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
};
</script>

<style lang="scss" src="@/assets/styles/aboutus.scss" scoped />
