<template>
  <div class="boolean-container">
    <ul class="boolean">
      <h1>BICOM metoda se najčešće primenjuje kod sledećih stanja:</h1>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Sindrom intolerancije na
        osnovne životne namirnice
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Bolna stanja kičme i
        zglobova
      </li>
      <li><img src="@/assets/images/check.svg" alt="" />Migrene</li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Sportske povrede i povrede
        druge vrste
      </li>
      <li><img src="@/assets/images/check.svg" alt="" />Alergije</li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Smetnje funkcionisanja
        digestivnog trakta
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Ginekološki poremećaji i
        bolne menstruacije
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Infekcije – bakterijske,
        virusne, gljivične (candida i dr.)
      </li>
      <li><img src="@/assets/images/check.svg" alt="" />Hormonski disbalans</li>
      <li><img src="@/assets/images/check.svg" alt="" />Parodontopija</li>
      <li><img src="@/assets/images/check.svg" alt="" />Stres</li>
      <li><img src="@/assets/images/check.svg" alt="" />Hronični umor</li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Preoperativna i
        postoperativna podrška
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Za detoksikaciju organizma
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Za odvikavanje od pušenja
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Za gojaznost – program
        mršavljenja
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Priprema za hemoterapiju
      </li>
      <li>
        <img src="@/assets/images/check.svg" alt="" />Terapija nakon
        hemoterapije
      </li>
    </ul>
  </div>
</template>

<style src="@/assets/styles/boolean.scss" lang="scss" scoped />
