<template>
  <div id="utisci">
    <h1>Vaši utisci</h1>
    <div class="body">
      <div class="carousel-container">
        <div class="arrow-container left-container" @click="step(-1)">
          <img src="@/assets/images/left-arrow.svg" alt="" />
        </div>
        <div class="carousel-comment">
          <div class="wrapper">
            <img src="@/assets/images/quotes.svg" alt="" />
            <p>{{ displayedComment.comment }}</p>
            <img class="upside-down" src="@/assets/images/quotes.svg" alt="" />
          </div>
          <h2>{{ displayedComment.name }}</h2>
        </div>

        <div class="arrow-container right-container" @click="step()">
          <img src="@/assets/images/right-arrow.svg" alt="" />
        </div>
      </div>
      <div class="navigation-container">
        <div
          :key="index"
          @click="setStep(index)"
          class="navigation-circle"
          v-for="(_, index) in comments"
          :class="{ active: currentStep === index }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
export default {
  setup() {
    const comments = [
      {
        name: "Ivana",
        comment:
          "Hvala puno na bezrezervnoj podršci koju mi pružate, na profesionalnosti i ljubaznosti. Borbu sa bubrezima smo dobili, sada napadamo dalje. :)",
      },
      {
        name: "Kristina",
        comment: "Mara je legenda, a i ovi ostali nisu loši. :)",
      },
      {
        name: "Irena",
        comment:
          "Hvala što postojite! Divan je osećaj kad napokon znaš da postoji život bez migrene! Volim vas!",
      },
      {
        name: "Elena",
        comment:
          "Puno pozitivne energije, ljubaznosti i prijatnosti! Odmorim se kad god dođem!",
      },
    ];

    const currentStep = ref(0);

    const displayedComment = computed(() => comments[currentStep.value]);

    const step = (step = 1) => {
      if (currentStep.value === 0 && step < 0)
        currentStep.value = comments.length;
      if (currentStep.value === comments.length - 1 && step > 0)
        currentStep.value = 0;
      else currentStep.value += step;
    };

    const setStep = (step) => (currentStep.value = step);

    return {
      step,
      setStep,
      comments,
      currentStep,
      displayedComment,
    };
  },
};
</script>

<style scoped lang="scss" src="@/assets/styles/comments.scss" />
