<template>
  <div class="container">
    <div class="page-container">
      <div class="title">
        <h1>MARIV</h1>
        <p>Centar za tretman BICOM aparatom</p>
      </div>
      <div class="welcome">
        <div class="aboutus">
          <h1>O nama</h1>
          <p>
            Želimo da vas upoznamo sa pozadinom hroničnih zdravstvenih smetnji i
            da vam u našoj ordinaciji predstavimo BICOM biorezonantnu metodu.
          </p>
          <a href="#aboutus">Pročitaj više</a>
        </div>
        <div class="optima">
          <h1>BICOM Optima</h1>
          <p>
            Sa BICOM aparatom mogu se obuhvatiti tipične frekvencijske mustre
            supstanci i odrediti njihovo delovanje na telo pacijenta. To se
            odvija veoma brzo i apsolutno bezbolno.
          </p>
          <a href="#bicom1">Pročitaj više</a>
        </div>
        <div class="body-check">
          <h1>BICOM Body Check</h1>
          <p>
            BICOM body check je neinvazivni, komplementarni medicinski
            dijagnostički sistem analiza koji skenira, obrađuje i opisuje
            energetsko stanje pacijenta u neverovatnom vremenskom roku.
          </p>
          <a href="#bicom2">Pročitaj više</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="@/assets/styles/welcomecomponent.scss" />
