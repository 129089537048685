<template>
  <about-us></about-us>
  <first-bicom></first-bicom>
  <div class="divider"><the-counter></the-counter></div>
  <second-bicom></second-bicom>
  <the-comments></the-comments>
  <contact-us></contact-us>
</template>

<style src="@/assets/styles/thepage.scss" lang="scss" scoped />
