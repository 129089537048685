<template>
  <section id="kontakt">
    <h1>Kontakt</h1>
    <div class="page-container">
      <div class="logo">
        <p id="address">Narodnih heroja 11<br />11000, Beograd, Srbija</p>
        <p id="link">www.mariv.rs</p>
        <button @click="isOpen = true">Prikaži mapu</button>
        <pop-up :open="isOpen" @close="isOpen = !isOpen"
          ><div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2829.870652969894!2d20.40562881574415!3d44.824199683938716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a65778769b5a9%3A0x2234ddee04410d94!2z0J3QsNGA0L7QtNC90LjRhSDRhdC10YDQvtGY0LAgMTEsINCR0LXQvtCz0YDQsNC0!5e0!3m2!1ssr!2srs!4v1649153798869!5m2!1ssr!2srs"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe></div
        ></pop-up>
      </div>
      <div class="divider"></div>
      <div class="info">
        <h2>Radno vreme:</h2>
        <p><span>pon-pet:</span> 12-19h</p>
        <p><span>subota:</span> 10-14h</p>
        <br />
        <h2>tel:</h2>
        <p>011/319 63 46<br />064/433 2570</p>
        <br />
        <h2>email:</h2>
        <p>marivcomp@outlook.com</p>
        <p>office@mariv.rs</p>
        <br />
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
};
</script>

<style scoped src="@/assets/styles/contactus.scss" lang="scss" />
