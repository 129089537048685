<template>
  <div class="container">
    <div class="page-container" ref="animateCounter">
      <div class="counter-container">
        <img src="@/assets/images/calendar.svg" alt="" />
        <p class="counter">{{ yearsWorking }}</p>
        <p>Godine poslovanja</p>
      </div>
      <div class="counter-container">
        <img src="@/assets/images/happy.svg" alt="" />
        <p class="counter">{{ happyClients }}</p>
        <p>Zadovoljni klijenti</p>
      </div>
      <div class="counter-container">
        <img src="@/assets/images/analysis.svg" alt="" />
        <p class="counter">{{ analysisDone }}</p>
        <p>Broj izvršenih analiza</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    const animateCounter = ref(null);
    const initialHappy = ref(1763);
    const happyClients = ref(0);
    const yearsWorking = ref(0);
    const initialWorking = ref(10);
    const analysisDone = ref(0);
    const initialAnalysis = ref(1800);
    const interval = ref(null);

    const incVariable = (variable, initVariable, step = 1) => {
      const nextVar = variable.value + step;
      variable.value =
        nextVar >= initVariable.value ? initVariable.value : nextVar;
    };
    const startCountAnimation = () => {
      happyClients.value = 0;
      yearsWorking.value = 0;
      analysisDone.value = 0;
      clearInterval(interval.value);
      interval.value = null;
      interval.value = setInterval(() => {
        if (happyClients.value >= initialHappy.value)
          clearInterval(interval.value);
        incVariable(
          happyClients,
          initialHappy,
          Math.floor(initialHappy.value / 30)
        );
        incVariable(
          yearsWorking,
          initialWorking,
          Math.floor(initialWorking.value / 10)
        );
        incVariable(
          analysisDone,
          initialAnalysis,
          Math.floor(initialAnalysis.value / 30)
        );
      }, 50);
    };

    const observer = new IntersectionObserver(startCountAnimation, {
      threshold: 1.0,
      rootMargin: "200px",
    });

    onMounted(() => observer.observe(animateCounter.value));

    return {
      happyClients,
      yearsWorking,
      analysisDone,
      animateCounter,
    };
  },
};
</script>

<style src="@/assets/styles/counter.scss" lang="scss" scoped />
