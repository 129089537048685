<template>
  <section id="bicom2">
    <div class="page-container">
      <div class="image-container">
        <img id="img" src="@/assets/images/bicom-bodycheck.jpg" />
      </div>
      <div class="text-container">
        <h1>
          BICOM<br />
          Body Check
        </h1>
        <p>
          BICOM body check je neinvazivni, komplementarni medicinski
          dijagnostički sistem analiza koji skenira, obrađuje i opisuje
          energetsko stanje pacijenta u neverovatnom vremenskom roku, a pored
          postojećih disbalansa moguće je ovom metodom prepoznati i energetske
          poremećaje mnogo pre nego što se isti manifestuju u telu, a značajne
          promene se prikazuju grafički i prema prioritetu. Današnji način
          života, ishrana i ekološka opterećenja mogu da oslabe telo u značajnoj
          meri. Energetski poremećaji se razvijaju, delimično skriveno, a da
          pacijent ni ne primeti. BICOM BodyCheck omogućava kompletnu
          bioenergetsku proveru zdravlja. On skenira frekvencije celog tela,
          unutrašnjih organa, kostiju i tkiva automatski do nivoa ćelija i
          analizira ih. Aparat nam omogućava da eliminišemo loše obrasce
          frekvenci bioloških sistema, tako što vršimo njihovu harmonizaciju i
          inverziju disharmoničnih obrazaca.
        </p>
        <p>
          <span>Analize koje se vrše tokom pregleda:</span><br />
          1) Biohemijska homeostaza<br />
          2) Elektromagnetna opterećenja, geopatska opterećenja, stresori (teški
          metali, hemikalije, lekovi, vakcine, zagađivači...)<br />
          3) Intolerancija na hranu i prehrambeni aditivi<br />
          4) Hormoni i produkti žlezdi<br />
          5) Bakterije, virusi, gljivice, paraziti i drugi mikroorganizmi<br />
          6) Stanje po sistemima organa i tkiva<br />
          7) Testiranje za Bahove kapi<br />
          8) Homeopatija<br />
          9) Fitoterapija<br />
          10) Stanje odnosa vitamina i minerala u organizmu
        </p>
        <button @click="isOpen = true">Prikaži više</button>
        <pop-up :open="isOpen" @close="isOpen = !isOpen">
          <div class="popup-container">
            <p class="p">
              Analizom se dobijaju preporuke za namirnice koje pogoduju Vašem
              organizmu, kao i one na koje organizam pokazuje intoleranciju (ne
              pogoduju u ishrani). Dijagnostika se obavlja vrlo komforno,
              pacijent drži slušalice na glavi tokom nekoliko minuta koliko
              traje Bicom Body Check analiza. Potpuno je bezbedno, bez ikakvih
              neželjenih efekata i kontraindikacija. Naš stručni tim kombinuje
              Bicom BodyCheck dijagnostičku analizu sa BICOM Optima
              dijagnostičko-terapeutskim sistemom, i u roku od ukupno 60 minuta
              dobija se kompletna analiza energetskog stanja organizma sa
              predlogom daljih Bicom tretmana za poboljšanje zdravstvenog
              stanja.
            </p>
          </div>
        </pop-up>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
};
</script>

<style scoped lang="scss" src="@/assets/styles/secondbicom.scss" />
