<template>
  <div class="container">
    <div class="footer-container">
      <div class="first footer-banner">
        <img src="@/assets/images/location.svg" alt="" />
        <div class="banner-wrapper">
          <a
            href="https://www.google.com/maps/place/%D0%9D%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%B8%D1%85+%D1%85%D0%B5%D1%80%D0%BE%D1%98%D0%B0+11,+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4/@44.8241959,20.4056288,17z/data=!3m1!4b1!4m5!3m4!1s0x475a65778769b5a9:0x2234ddee04410d94!8m2!3d44.8241959!4d20.4078175"
            >Nađite nas na mapi</a
          >
        </div>
      </div>
      <div class="second footer-banner">
        <img src="@/assets/images/phone.svg" alt="" />
        <div class="banner-wrapper">
          <p>Pozovite nas da zakažete pregled</p>
          <a href="tel:(011)3196346">011/319 63 46</a>
        </div>
      </div>
      <div class="third footer-banner">
        <img src="@/assets/images/mail.svg" alt="" />
        <div class="banner-wrapper">
          <a href="mailto:office@mariv.rs">Pošaljite nam poruku</a>
        </div>
      </div>
    </div>
    <div class="black-footer">
      <p>© 2022 <span>Mariv</span> centar za tretman BICOM aparatom</p>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/assets/styles/footer.scss" />
