<template>
  <section id="bicom1">
    <div class="bg-image"></div>
    <div class="background-oregon-grapes">
      <div class="page-container">
        <div class="text-container">
          <h1>BICOM<br />Optima</h1>
          <p>
            Mi živimo u doba komunikacija i informacija i došlo je vreme da
            postanemo svesni činjenice, da telo može funkcionisati i vršiti
            regulacione procese zbog toga što se u njemu odvija komunikacija, a
            time i razmena informacija između ćelija. U međuvremenu je dokazano
            da ćelije međusobno komuniciraju uz pomoć "svetlosnih bliceva". One
            uz pomoć određenih frekvencija razmenjuju informacije.<br />
            U zdravom telu ova razmena informacija funkcioniše neometano. Tako
            svaka ćelija odnosno svaki deo tela može da ispuni svoje zadatke.<br />
            Ako ometajuće supstance (toksini, virusi, bakterije, itd.) ili
            opterećujuća zračenja deluju na telo, njhove ometajuće frekvencijske
            mustre mogu sprečiti komunikaciju između ćelija.<br />
            Ukoliko je komunikacija između ćelija ometena, tada će svakako i rad
            ćelija biti sprečen, što se pre ili kasnije može pokazati kroz
            nejasne osećajne smetnje, radne sposobnosti, hronični umor, a
            kasnije takođe kroz organske promene i odgovarajuće simptome.<br />
            Simptomi često nastupaju tamo gde već - takođe često nasledno
            uslovljeno postoji određena slabost.Sa BICOM aparatom mogu se
            obuhvatiti tipične frekvencijske mustre supstanci i odrediti njihovo
            delovanje na telo pacijenta. To se odvija veoma brzo i apsolutno
            bezbolno. Tako se u mnogim slučajevima može otkriti koja opterećenja
            čine pacijenta bolesnim (npr. bakterije, virusi, elektrosmog, zubni
            materijali, alergeni itd).<br />
            Kod terapije sa BICOM aparatom obuhvataju se ometajuće frekvencijske
            mustre koje se u aparatu preoblikuju u terapijske frekvencijske
            mustre i kao takve daju pacijentu.<br />
            Tako se mogu reducirati ometajuće frekvencijske mustre i otkloniti
            osnovne smetnje u telu. Opterećujuće supstance se oslobađaju i
            izlučuju iz tela. Sopstvena telesna regulacija je ponovo aktivirana.
            Biorezonanca se koristi tako sto je osoba uključena u sistem,
            odnosno kolo aparata, preko ulazne katode i modulacione (izlazne)
            elektrode na leđima.
          </p>
          <div class="popup-container">
            <button @click="isOpen = true">Prikaži više</button>
            <pop-up :open="isOpen" @close="isOpen = !isOpen">
              <div class="popup-wrapper">
                <h3 id="title">Proces biorezonantne metode:</h3>
                <p>
                  <span id="paragraph">1. Dijagnoza</span><br />Jednim posebnim,
                  bezbolnim testnim postupkom, terapeut otkriva da li kod vas
                  postoji nepodnošljivost na neke supstance, da li su određeni
                  organi oslabljeni ili neki toksini imaju negativno delovanje
                  na telo itd. Tako se u mnogim slučajevima može brzo otkriti
                  često prikriveni uzrok zdravstvenih problema.<br /><br />
                  <span id="paragraph">2. Terapija</span><br />
                  Uz pomoć dijagnoze terapeutu postaje jasno koja opterećenja
                  vaš organizam najslabije podnosi i takva optrećenja potrebno
                  je lečiti. U većini slučajeva izrađuje se individualni plan
                  terapije dogovara o pratećim merama i postupcima. Prilikom
                  tretmana sa BICOM aparatom postavljaju se elektrode na
                  pacijenta koji može opušteno sedeti ili ležati. Jedan tretman
                  traje prosečno između 5-30 minuta. Terapija se doživljava kao
                  prijatna i potpuno je bezbolna. Cilj terapije je da se umanje
                  svi uticaji koji izazivaju bolest i tako ponovo aktiviraju
                  sopstvene odbrambene snage. Broj tretmana zavisi od različitih
                  faktora. Molimo vas da informišete svog terapeuta o svim
                  promenama koje osećate.
                </p>
              </div>
            </pop-up>
          </div>
        </div>
        <div class="image-container">
          <img id="top" src="@/assets/images/imageone.jpg" alt="" />
          <img id="bottom" src="@/assets/images/imagetwo.jpg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    };
  },
};
</script>

<style scoped lang="scss" src="@/assets/styles/firstbicom.scss" />
