<template>
  <div class="modal" v-show="open" @click="$emit('close')">
    <div class="modal-inner" @click.stop>
      <div class="modal-content">
        <slot />
        <button type="button" @click="$emit('close')">Zatvori</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup() {},
};
</script>

<style scoped src="@/assets/styles/popup.scss" lang="scss" />
